// third-party
import { FormattedMessage } from 'react-intl';
 
// assets
import {
  BuildOutlined,
  CalendarOutlined, 
  CustomerServiceFilled,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  MedicineBoxOutlined,
  UserAddOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from '../types/menu';

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceFilled,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  MedicineBoxOutlined,
  UserAddOutlined
};

// hooks -----------------------------------------------
// import useAuth from 'hooks/useAuth';
import { JWTContextType } from 'types/auth';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const Applications = (user: JWTContextType) => {
  
  let _children_all = [
    {
      id: 'bodypart',
      title: <FormattedMessage id="bodypart" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="centre-list" />,
          type: 'item',
          url: '/apps/bodypart/list'
        }
      ]
    },
    {
      id: 'imagingCentre',
      title: <FormattedMessage id="imagingCentre" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="centre-list" />,
          type: 'item',
          url: '/apps/imaging-centre/list'
        },
        // {
        //   id: 'imagingCentre-card',
        //   title: <FormattedMessage id="centre-cards" />,
        //   type: 'item',
        //   url: '/apps/imagingCentre/imagingCentre-card'
        // },
        {
          id: 'charges',
          title: <FormattedMessage id="centre-charges" />,
          type: 'item',
          url: '/apps/imaging-centre/charges'
        },
        {
          id: 'extra-charges',
          title: <FormattedMessage id="centre-extra-charges" />,
          type: 'item',
          url: '/apps/imaging-centre/extra-charges'
        }
      ]
    },
    {
      id: 'radiologist',
      title: <FormattedMessage id="radiologist" />,
      type: 'collapse',
      icon: icons.UserAddOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="radiologist-list" />,
          type: 'item',
          url: '/apps/radiologist/list'
        },
        {
          id: 'charges',
          title: <FormattedMessage id="radiologist-charges" />,
          type: 'item',
          url: '/apps/radiologist/charges'
        },
        {
          id: 'grade-charges',
          title: <FormattedMessage id="radiologist-grade-charges" />,
          type: 'item',
          url: '/apps/radiologist/grade-charges'
        },
        {
          id: 'extra-charges',
          title: <FormattedMessage id="radiologist-extra-charges" />,
          type: 'item',
          url: '/apps/radiologist/extra-charges'
        },
        // {
        //   id: 'sales-radiologist-profiles',
        //   title: <FormattedMessage id="sales-radiologist-profiles" />,
        //   type: 'item',
        //   url: '/apps/radiologist/profiles'  
        // }
      ]
    },
    {
      id: 'invoice',
      title: <FormattedMessage id="invoice" />,
      // url: '/apps/invoice/dashboard',
      type: 'collapse',
      icon: icons.FileTextOutlined,
      breadcrumbs: true,
      children: [
        {
          id: 'centre-zero-charges',
          title: <FormattedMessage id="centre-zero-charges" />,
          type: 'item',
          url: '/apps/invoice/centre/zero-charges'
        },
        {
          id: 'radiologist-zero-charges',
          title: <FormattedMessage id="radiologist-zero-charges" />,
          type: 'item',
          url: '/apps/invoice/radiologist/zero-charges'
        },
        {
          id: 'generate-invoice',
          title: <FormattedMessage id="generate-invoice" />,
          type: 'item',
          url: '/apps/invoice/centre/generate-invoice'
        },
        {
          id: 'generate-billing',
          title: <FormattedMessage id="generate-billing" />,
          type: 'item',
          url: '/apps/invoice/radiologist/generate-billing'
        },
        // {
        //   id: 'create',
        //   title: <FormattedMessage id="create" />,
        //   type: 'item',
        //   url: '/apps/invoice/create'
        // },
        // {
        //   id: 'details',
        //   title: <FormattedMessage id="details" />,
        //   type: 'item',
        //   url: '/apps/invoice/details/1'
        // },
        // {
        //   id: 'list',
        //   title: <FormattedMessage id="list" />,
        //   type: 'item',
        //   url: '/apps/invoice/list'
        // },
        // {
        //   id: 'edit',
        //   title: <FormattedMessage id="edit" />,
        //   type: 'item',
        //   url: '/apps/invoice/edit/1'
        // }
      ]
    },
    {
      id: 'operations',
      title: <FormattedMessage id="operations" />,
      type: 'collapse',
      icon: icons.FileTextOutlined,
      breadcrumbs: true,
      children: [
        {
          id: 'studies',
          // title: <FormattedMessage id="list" />,
          title: "Studies",
          type: 'item',
          url: '/apps/operations/study'
        } 
      ]
    },
    {
      id: 'study',
      title: <FormattedMessage id="study" />,
      type: 'collapse',
      icon: icons.FileTextOutlined,
      breadcrumbs: true,
      children: [
        {
          id: 'nandico',
          // title: <FormattedMessage id="list" />,
          title: "Nandico",
          type: 'item',
          url: '/apps/study/nandico'
        },
        {
          id: 'stradus',
          // title: <FormattedMessage id="list" />,
          title: "Stradus",
          type: 'item',
          url: '/apps/study/stradus'
        } 
      ]
    }
  ];


  let _children_sales = [{
    id: 'imagingCentre',
    title: <FormattedMessage id="imagingCentre" />,
    type: 'collapse',
    icon: icons.MedicineBoxOutlined,
    children: [
      {
        id: 'list',
        title: <FormattedMessage id="centre-list" />,
        type: 'item',
        url: '/apps/imaging-centre/list'
      },
      // {
      //   id: 'imagingCentre-card',
      //   title: <FormattedMessage id="centre-cards" />,
      //   type: 'item',
      //   url: '/apps/imagingCentre/imagingCentre-card'
      // },
      {
        id: 'charges',
        title: <FormattedMessage id="centre-charges" />,
        type: 'item',
        url: '/apps/imaging-centre/charges'
      },
      {
        id: 'extra-charges',
        title: <FormattedMessage id="centre-extra-charges" />,
        type: 'item',
        url: '/apps/imaging-centre/extra-charges'
      },
      {
        id: 'centre-zero-charges',
        title: <FormattedMessage id="centre-zero-charges" />,
        type: 'item',
        url: '/apps/invoice/centre/zero-charges'
      }
    ]
  },
  ];


  let _children_HR = [        
    {
      id: 'radiologist',
      title: <FormattedMessage id="radiologist" />,
      type: 'collapse',
      icon: icons.UserAddOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="radiologist-list" />,
          type: 'item',
          url: '/apps/radiologist/list'
        },
        {
          id: 'charges',
          title: <FormattedMessage id="radiologist-charges" />,
          type: 'item',
          url: '/apps/radiologist/charges'
        },
        {
          id: 'grade-charges',
          title: <FormattedMessage id="radiologist-grade-charges" />,
          type: 'item',
          url: '/apps/radiologist/grade-charges'
        },
        {
          id: 'extra-charges',
          title: <FormattedMessage id="radiologist-extra-charges" />,
          type: 'item',
          url: '/apps/radiologist/extra-charges'
        },
        {
          id: 'radiologist-zero-charges',
          title: <FormattedMessage id="radiologist-zero-charges" />,
          type: 'item',
          url: '/apps/invoice/radiologist/zero-charges'
        }
      ]
    },
  ];

  let _children_data_entry = [{
    id: 'study',
    title: <FormattedMessage id="study" />,
    type: 'collapse',
    icon: icons.FileTextOutlined,
    breadcrumbs: true,
    children: [
      {
        id: 'nandico',
        // title: <FormattedMessage id="list" />,
        title: "Nandico",
        type: 'item',
        url: '/apps/study/nandico'
      },
        {
          id: 'stradus',
          // title: <FormattedMessage id="list" />,
          title: "Stradus",
          type: 'item',
          url: '/apps/study/stradus'
      } 

      // {
      //   id: 'stradus',
      //   // title: <FormattedMessage id="list" />,
      //   title: "Stradus",
      //   type: 'item',
      //   url: '/apps/study/stradus'
      // } 
    ]
  }];

  let _children_accounts = [
     {
      id: 'imagingCentre',
      title: <FormattedMessage id="imagingCentre" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="centre-list" />,
          type: 'item',
          url: '/apps/imaging-centre/list'
        },
        {
          id: 'generate-invoice',
          title: <FormattedMessage id="generate-invoice" />,
          type: 'item',
          url: '/apps/invoice/centre/generate-invoice'
        },
      ]
    },
    {
      id: 'radiologist',
      title: <FormattedMessage id="radiologist" />,
      type: 'collapse',
      icon: icons.UserAddOutlined,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="radiologist-list" />,
          type: 'item',
          url: '/apps/radiologist/list'
        },
      ]
    }
  ];

  let _children_operations = [  {
    id: 'operations',
    title: <FormattedMessage id="operations" />,
    type: 'collapse',
    icon: icons.FileTextOutlined,
    breadcrumbs: true,
    children: [
      {
        id: 'studies',
        // title: <FormattedMessage id="list" />,
        title: "Studies",
        type: 'item',
        url: '/apps/operations/study'
      } 
    ]
  },
  {
    id: 'datasync',
    title: <FormattedMessage id="datasync" />,
    type: 'collapse',
    icon: icons.FileTextOutlined,
    breadcrumbs: true,
    children: [
      {
        id: 'nandico',
        // title: <FormattedMessage id="list" />,
        title: "Nandico",
        type: 'item',
        url: '/apps/study/nandico'
      },
      {
        id: 'stradus',
        // title: <FormattedMessage id="list" />,
        title: "Stradus",
        type: 'item',
        url: '/apps/study/stradus'
      } 
    ]
  }
  ];
 


  let children: NavItemType[] = [];
  const roles = user.user?.permissions?.split(',') ?? [];
  if (roles.length > 0)
    if (roles.filter(role => role == 'ADMIN').length > 0)
      children = _children_all;
    else if (roles.filter(role => role == 'SALES_HEAD').length > 0)
      children = _children_sales;
    else if (roles.filter(role => role == 'DATA_EXECUTIVE').length > 0)
      children = _children_data_entry;
    else if (roles.filter(role => role == 'HR').length > 0)
      children = _children_HR;
    else if (roles.filter(role => role == 'OPERATIONS').length > 0)
      children = _children_operations
    else if (roles.filter(role => role == 'ACCOUNTS').length > 0)
      children = _children_accounts


  const appLinks: NavItemType = {
    id: 'group-applications',
    title: <FormattedMessage id="applications" />,
    icon: icons.AppstoreAddOutlined,
    type: 'group',
    children: children
  };
  return appLinks
};
export default Applications;


// const applications: NavItemType = {
//   id: 'group-applications',
//   title: <FormattedMessage id="applications" />,
//   icon: icons.AppstoreAddOutlined,
//   type: 'group',
//   children: _children_data_entry
// };

// export default applications;
 

// ---------------------------------------------------------------------------------------------------------


// import React from 'react';
// import useAuth from 'hooks/useAuth';

// // third-party
// import { FormattedMessage } from 'react-intl';
 
// // assets
// import {
//   BuildOutlined,
//   CalendarOutlined, 
//   CustomerServiceFilled,
//   FileTextOutlined,
//   MessageOutlined,
//   ShoppingCartOutlined,
//   UserOutlined,
//   AppstoreAddOutlined,
//   MedicineBoxOutlined,
//   UserAddOutlined
// } from '@ant-design/icons';

// // type
// import { NavItemType } from '../types/menu';

// // icons
// const icons = {
//   BuildOutlined,
//   CalendarOutlined,
//   CustomerServiceFilled,
//   MessageOutlined,
//   ShoppingCartOutlined,
//   UserOutlined,
//   AppstoreAddOutlined,
//   FileTextOutlined,
//   MedicineBoxOutlined,
//   UserAddOutlined
// };

// // ==============================|| MENU ITEMS - APPLICATIONS ||============================== //


// const Applications = () => {
//   const { user } = useAuth();
//   const centreLinks ={
//     id: 'imagingCentre',
//     title: <FormattedMessage id="imagingCentre" />,
//     type: 'collapse',
//     icon: icons.MedicineBoxOutlined,
//     children: [
//       {
//         id: 'list',
//         title: <FormattedMessage id="centre-list"  />,
//         type: 'item',
//         url: '/apps/imaging-centre/list' 
//       },
//       // {
//       //   id: 'imagingCentre-card',
//       //   title: <FormattedMessage id="centre-cards" />,
//       //   type: 'item',
//       //   url: '/apps/imagingCentre/imagingCentre-card'
//       // },
//       {
//         id: 'charges',
//         title: <FormattedMessage id="centre-charges" />,
//         type: 'item',
//         url: '/apps/imaging-centre/charges'
//       },
//       {
//         id: 'extra-charges',
//         title: <FormattedMessage id="centre-extra-charges" />,
//         type: 'item',
//         url: '/apps/imaging-centre/extra-charges'
//       }
//     ]
//   };
  
//   let children: NavItemType[] = [];
  
//   if (user?.permisions?.includes('ADMIN')) {
//     children = [
//       {
//         id: 'bodypart',
//         title: <FormattedMessage id="bodypart" />,
//         type: 'collapse',
//         icon: icons.MedicineBoxOutlined,
//         children: [
//           {
//             id: 'list',
//             title: <FormattedMessage id="centre-list" />,
//             type: 'item',
//             url: '/apps/bodypart/list'
//           }
//         ]
//       },
//       centreLinks,
//       {
//         id: 'radiologist',
//         title: <FormattedMessage id="radiologist" />,
//         type: 'collapse',
//         icon: icons.UserAddOutlined,
//         children: [
//           {
//             id: 'list',
//             title: <FormattedMessage id="radiologist-list" />,
//             type: 'item',
//             url: '/apps/radiologist/list'
//           },
//           {
//             id: 'charges',
//             title: <FormattedMessage id="radiologist-charges" />,
//             type: 'item',
//             url: '/apps/radiologist/charges'
//           },
//           {
//             id: 'extra-charges',
//             title: <FormattedMessage id="radiologist-extra-charges" />,
//             type: 'item',
//             url: '/apps/radiologist/extra-charges'
//           }
//         ]
//       },
//       {
//         id: 'invoice',
//         title: <FormattedMessage id="invoice" />,
//         url: '/apps/invoice/dashboard',
//         type: 'collapse',
//         icon: icons.FileTextOutlined,
//         breadcrumbs: true,
//         children: [
//           {
//             id: 'centre-zero-charges',
//             title: <FormattedMessage id="centre-zero-charges" />,
//             type: 'item',
//             url: '/apps/invoice/centre/zero-charges'
//           },
//           {
//             id: 'radiologist-zero-charges',
//             title: <FormattedMessage id="radiologist-zero-charges" />,
//             type: 'item',
//             url: '/apps/invoice/radiologist/zero-charges'
//           },
//           {
//             id: 'generate-invoice',
//             title: <FormattedMessage id="generate-invoice" />,
//             type: 'item',
//             url: '/apps/invoice/centre/generate-invoice'
//           },
//           {
//             id: 'generate-billing',
//             title: <FormattedMessage id="generate-billing" />,
//             type: 'item',
//             url: '/apps/invoice/radiologist/generate-billing'
//           },
//           {
//             id: 'create',
//             title: <FormattedMessage id="create" />,
//             type: 'item',
//             url: '/apps/invoice/create'
//           },
//           {
//             id: 'details',
//             title: <FormattedMessage id="details" />,
//             type: 'item',
//             url: '/apps/invoice/details/1'
//           },
//           {
//             id: 'list',
//             title: <FormattedMessage id="list" />,
//             type: 'item',
//             url: '/apps/invoice/list'
//           },
//           {
//             id: 'edit',
//             title: <FormattedMessage id="edit" />,
//             type: 'item',
//             url: '/apps/invoice/edit/1'
//           }
//         ]
//       },
//       {
//         id: 'study',
//         title: <FormattedMessage id="study" />,
//         type: 'collapse',
//         icon: icons.FileTextOutlined,
//         breadcrumbs: true,
//         children: [
//           {
//             id: 'list',
//             title: <FormattedMessage id="list" />,
//             type: 'item',
//             url: '/apps/study/list'
//           }
//         ]
//       }
//     ];
//   }
//   else if (user?.permisions?.includes('ADMIN')) 
//   {
//     children = [centreLinks];
//   }

//   return {
//     id: 'group-applications',
//     title: <FormattedMessage id="applications" />,
//     icon: icons.AppstoreAddOutlined,
//     type: 'group',
//     children
//   };
  
// };

// export default Applications;

