/* tslint:disable */
/* eslint-disable */
/**
 * eminence-pac-system
 * eminence-pac-system API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { RadiologistGradeCharges } from '../models';
// @ts-ignore
import { RadiologistGradeChargesCreate } from '../models';
// @ts-ignore
import { RadiologistGradeChargesUpdate } from '../models';
/**
 * RadiologistGradeChargesApi - axios parameter creator
 * @export
 */
export const RadiologistGradeChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Charges Bulk
         * @param {Array<RadiologistGradeChargesCreate>} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChargesBulk: async (radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistGradeChargesCreate' is not null or undefined
            assertParamExists('createChargesBulk', 'radiologistGradeChargesCreate', radiologistGradeChargesCreate)
            const localVarPath = `/api/v1/radiologist/grade/charges/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistGradeChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Grade Charge
         * @param {RadiologistGradeChargesCreate} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGradeCharge: async (radiologistGradeChargesCreate: RadiologistGradeChargesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'radiologistGradeChargesCreate' is not null or undefined
            assertParamExists('createGradeCharge', 'radiologistGradeChargesCreate', radiologistGradeChargesCreate)
            const localVarPath = `/api/v1/radiologist/grade/charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistGradeChargesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGradeCharge: async (chargeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('deleteGradeCharge', 'chargeId', chargeId)
            const localVarPath = `/api/v1/radiologist/grade/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Grade Charges
         * @param {number} gradeId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeCharges: async (gradeId: number, sort?: string, range?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gradeId' is not null or undefined
            assertParamExists('getGradeCharges', 'gradeId', gradeId)
            const localVarPath = `/api/v1/radiologist/grade/{grade_id}/charges`
                .replace(`{${"grade_id"}}`, encodeURIComponent(String(gradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Grade Charge
         * @param {number} chargeId 
         * @param {RadiologistGradeChargesUpdate} radiologistGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGradeCharge: async (chargeId: number, radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargeId' is not null or undefined
            assertParamExists('updateGradeCharge', 'chargeId', chargeId)
            // verify required parameter 'radiologistGradeChargesUpdate' is not null or undefined
            assertParamExists('updateGradeCharge', 'radiologistGradeChargesUpdate', radiologistGradeChargesUpdate)
            const localVarPath = `/api/v1/radiologist/grade/charges/{charge_id}`
                .replace(`{${"charge_id"}}`, encodeURIComponent(String(chargeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiologistGradeChargesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RadiologistGradeChargesApi - functional programming interface
 * @export
 */
export const RadiologistGradeChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RadiologistGradeChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Charges Bulk
         * @param {Array<RadiologistGradeChargesCreate>} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChargesBulk(radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChargesBulk(radiologistGradeChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Grade Charge
         * @param {RadiologistGradeChargesCreate} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGradeCharge(radiologistGradeChargesCreate: RadiologistGradeChargesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistGradeCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGradeCharge(radiologistGradeChargesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGradeCharge(chargeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGradeCharge(chargeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Grade Charges
         * @param {number} gradeId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGradeCharges(gradeId: number, sort?: string, range?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RadiologistGradeCharges>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGradeCharges(gradeId, sort, range, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Grade Charge
         * @param {number} chargeId 
         * @param {RadiologistGradeChargesUpdate} radiologistGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGradeCharge(chargeId: number, radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadiologistGradeCharges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGradeCharge(chargeId, radiologistGradeChargesUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RadiologistGradeChargesApi - factory interface
 * @export
 */
export const RadiologistGradeChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RadiologistGradeChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Charges Bulk
         * @param {Array<RadiologistGradeChargesCreate>} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChargesBulk(radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>, options?: any): AxiosPromise<any> {
            return localVarFp.createChargesBulk(radiologistGradeChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Grade Charge
         * @param {RadiologistGradeChargesCreate} radiologistGradeChargesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGradeCharge(radiologistGradeChargesCreate: RadiologistGradeChargesCreate, options?: any): AxiosPromise<RadiologistGradeCharges> {
            return localVarFp.createGradeCharge(radiologistGradeChargesCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Grade Charge
         * @param {number} chargeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGradeCharge(chargeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteGradeCharge(chargeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Grade Charges
         * @param {number} gradeId 
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {string} [filter] Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeCharges(gradeId: number, sort?: string, range?: string, filter?: string, options?: any): AxiosPromise<Array<RadiologistGradeCharges>> {
            return localVarFp.getGradeCharges(gradeId, sort, range, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Grade Charge
         * @param {number} chargeId 
         * @param {RadiologistGradeChargesUpdate} radiologistGradeChargesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGradeCharge(chargeId: number, radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate, options?: any): AxiosPromise<RadiologistGradeCharges> {
            return localVarFp.updateGradeCharge(chargeId, radiologistGradeChargesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createChargesBulk operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiCreateChargesBulkRequest
 */
export interface RadiologistGradeChargesApiCreateChargesBulkRequest {
    /**
     * 
     * @type {Array<RadiologistGradeChargesCreate>}
     * @memberof RadiologistGradeChargesApiCreateChargesBulk
     */
    readonly radiologistGradeChargesCreate: Array<RadiologistGradeChargesCreate>
}

/**
 * Request parameters for createGradeCharge operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiCreateGradeChargeRequest
 */
export interface RadiologistGradeChargesApiCreateGradeChargeRequest {
    /**
     * 
     * @type {RadiologistGradeChargesCreate}
     * @memberof RadiologistGradeChargesApiCreateGradeCharge
     */
    readonly radiologistGradeChargesCreate: RadiologistGradeChargesCreate
}

/**
 * Request parameters for deleteGradeCharge operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiDeleteGradeChargeRequest
 */
export interface RadiologistGradeChargesApiDeleteGradeChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistGradeChargesApiDeleteGradeCharge
     */
    readonly chargeId: number
}

/**
 * Request parameters for getGradeCharges operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiGetGradeChargesRequest
 */
export interface RadiologistGradeChargesApiGetGradeChargesRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistGradeChargesApiGetGradeCharges
     */
    readonly gradeId: number

    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof RadiologistGradeChargesApiGetGradeCharges
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof RadiologistGradeChargesApiGetGradeCharges
     */
    readonly range?: string

    /**
     * Format: &#x60;{\&quot;filter_field\&quot;:\&quot;filter_value\&quot;}&#x60;
     * @type {string}
     * @memberof RadiologistGradeChargesApiGetGradeCharges
     */
    readonly filter?: string
}

/**
 * Request parameters for updateGradeCharge operation in RadiologistGradeChargesApi.
 * @export
 * @interface RadiologistGradeChargesApiUpdateGradeChargeRequest
 */
export interface RadiologistGradeChargesApiUpdateGradeChargeRequest {
    /**
     * 
     * @type {number}
     * @memberof RadiologistGradeChargesApiUpdateGradeCharge
     */
    readonly chargeId: number

    /**
     * 
     * @type {RadiologistGradeChargesUpdate}
     * @memberof RadiologistGradeChargesApiUpdateGradeCharge
     */
    readonly radiologistGradeChargesUpdate: RadiologistGradeChargesUpdate
}

/**
 * RadiologistGradeChargesApi - object-oriented interface
 * @export
 * @class RadiologistGradeChargesApi
 * @extends {BaseAPI}
 */
export class RadiologistGradeChargesApi extends BaseAPI {
    /**
     * 
     * @summary Create Charges Bulk
     * @param {RadiologistGradeChargesApiCreateChargesBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public createChargesBulk(requestParameters: RadiologistGradeChargesApiCreateChargesBulkRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).createChargesBulk(requestParameters.radiologistGradeChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Grade Charge
     * @param {RadiologistGradeChargesApiCreateGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public createGradeCharge(requestParameters: RadiologistGradeChargesApiCreateGradeChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).createGradeCharge(requestParameters.radiologistGradeChargesCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Grade Charge
     * @param {RadiologistGradeChargesApiDeleteGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public deleteGradeCharge(requestParameters: RadiologistGradeChargesApiDeleteGradeChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).deleteGradeCharge(requestParameters.chargeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Grade Charges
     * @param {RadiologistGradeChargesApiGetGradeChargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public getGradeCharges(requestParameters: RadiologistGradeChargesApiGetGradeChargesRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).getGradeCharges(requestParameters.gradeId, requestParameters.sort, requestParameters.range, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Grade Charge
     * @param {RadiologistGradeChargesApiUpdateGradeChargeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RadiologistGradeChargesApi
     */
    public updateGradeCharge(requestParameters: RadiologistGradeChargesApiUpdateGradeChargeRequest, options?: AxiosRequestConfig) {
        return RadiologistGradeChargesApiFp(this.configuration).updateGradeCharge(requestParameters.chargeId, requestParameters.radiologistGradeChargesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
